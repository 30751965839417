import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from "gatsby"

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const Container = styled.div`
  color: #ffffff;
  width: 100%;

  .item-slider {
    height: 330px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #9E9E9E;
    text-align: center;

    a {
      display: block;
      width: 100%;
      height: 100%;
    }

    .mobileImage,
    .desktopImage {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      display: block;
    }

    .desktopImage {
      display: none;
    }
  }

  @media (min-width: 767px) {
    .item-slider {
      height: 420px;

      .desktopImage {
        display: block;
      }
      .mobileImage {
        display: none;
      }
    }
  }
`;

export default function Banner() {
  const result = useStaticQuery(graphql`
    {
      allPrismicBannerHomeMeuAmbients {
        edges {
          node {
            data {
              imagem_mobile {
                url
              }
              imagem_desktop {
                url
              }
              link {
                url
              }
            }
          }
        }
      }
    }
  `)
  
  const { allPrismicBannerHomeMeuAmbients } = result

  return (
    <Container>
      <Carousel
        responsive={responsive}
        showDots={true}
        arrows={false}
        draggable={true}
      >
        {allPrismicBannerHomeMeuAmbients.edges.map((banner, index) => {
          return (
            <div className="item-slider" key={index}>
              <a href={ banner.node.data.link ? banner.node.data.link.url : '' } target="_blank" rel="noreferrer">
                <span
                  className="desktopImage"
                  style={ banner.node.data.imagem_desktop && { backgroundImage: `url(${banner.node.data.imagem_desktop.url})` } }
                />
                <span
                  className="mobileImage"
                  style={ banner.node.data.imagem_mobile && { backgroundImage: `url(${banner.node.data.imagem_mobile.url})` } }
                />
              </a>
            </div>
          )
        })}
      </Carousel>
    </Container>
  )
}